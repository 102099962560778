import { mapActions, mapMutations } from 'vuex'

import { cnpj, cpf } from 'cpf-cnpj-validator'

export const methods = {
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', ['getRegioes', 'getRepresentantes']),
  ...mapActions('motoristas', ['getMotoristas']),
  ...mapMutations('relatorioServicoEixo', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value) {
      const paramsWithCNPJ = ["fornecedor", "terminal", "cliente", "transportadora"]
      const paramsWithCPF = ["representante", "motorista"]

      if (paramsWithCNPJ.includes(key)) fixedParams[key] = cnpj.strip(param.value)
      else if (paramsWithCPF.includes(key)) fixedParams[key] = cpf.strip(param.value)
      else fixedParams[key] = param.value

    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(this.handleFixParams, new Object())
  },

  addParamsFilter() {
    localStorage.setItem("filterOptionsDetailsServicoEixo", JSON.stringify(this.params))
  },

  getParamsFilter() {
    if (localStorage.getItem("filterOptionsDetailsServicoEixo")) {
      this.params = JSON.parse(localStorage.getItem("filterOptionsDetailsServicoEixo"))
    }
  },
  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = { ...this.fixParams() }
      this.UPDATE_PARAMS(fixedParams)
      this.addParamsFilter()
      this.$emit("search")
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    if (localStorage.getItem("filterOptionsDetailsServicoEixo")) {
      this.params = JSON.parse(localStorage.getItem("filterOptionsDetailsServicoEixo"))
    }
    this.allOptions.forEach(someOption => {
      this.params[someOption].visible = false
    })
    this.selectedOptions.forEach(option => {
      this.params[option].visible = true
      this.params[option].visible = true
    })
    this.allOptions.forEach(someOption => {
      if (this.params[someOption].visible == false) {
        this.params[someOption].value = ''
      }
    })
    this.addParamsFilter()
    this.showDaddy()
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(dependent => this.params[dependent].visible)
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  }
}
