<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <servico-eixo-list-filtro @search="handleGetServico" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Serviços">
          <listagem-relatorio-servico
            ref="listagem"
          ></listagem-relatorio-servico>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import servicoEixoListFiltro from '@/components/financeiro/servico/relatorioEixo/ServicoEixoListFiltro.vue'
import ListagemRelatorioServico from '@/components/financeiro/servico/relatorioEixo/Listagem.vue'
import { toReal, parseDatetime } from '@/mixins/convertion.js'
export default {
  name: 'Servico',

  components: {
    ListagemRelatorioServico,
    servicoEixoListFiltro,
  },
  mixins: [toReal, parseDatetime],
  data: function () {
    return {
      tab: 'relatorioTransportadora',
      page: {
        title: 'Relatório de Servico',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Serviço',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
    }
  },
  methods: {
    async handleGetServico() {
      await this.$refs['listagem'].getServico()
    },
  },
}
</script>
